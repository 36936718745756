import React from 'react';
import { Route } from 'react-router-dom';
import { SparkApp } from 'spark-core-dx';
import { DomainRoute } from 'spark-core-dx/domains';
import Home from './domains/home/screens/Home';
import HomeDomain from './domains/home/HomeDomain';
import { ItemServiceFactory as CurrentItemServiceFactory } from './services/ItemServiceFactory';

const App = () => {  
  const itemSvcFactory = new CurrentItemServiceFactory();
  return (
    <SparkApp 
      itemServiceFactory={itemSvcFactory}
      addonRoutes={[
       <Route key={"PublicDomain"} element={<DomainRoute domainName={'Public'} component={HomeDomain} />} >
         <Route exact path={'/'} element={<Home />} />
       </Route>
    ]} />
  )
}

export default App;
