/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import { ItemService } from 'spark-core-dx/services'

export const Operations = {
  TenantAppItem : {
     TenantAppList: "TenantAppList",
  },
}

export class AGAppConfigItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/AGAppConfig", coreState, coreContext, "APP", null, isWarning);
    }

}

export class AuthUserItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/AuthUser", coreState, coreContext, "APP", null, isWarning);
    }

}

export class ClientKeySecretItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/ClientKeySecret", coreState, coreContext, "APP", null, isWarning);
    }

}

export class RefreshTokenItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/RefreshToken", coreState, coreContext, "APP", null, isWarning);
    }

}

export class TenantAppItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/TenantApp", coreState, coreContext, "APP", null, isWarning);
    }

}

export class TenantItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/Tenant", coreState, coreContext, "APP", null, isWarning);
    }

}

export class TokenSecretItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/TokenSecret", coreState, coreContext, "APP", null, isWarning);
    }

}

export class GraphApplicationItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/GraphApplication", coreState, coreContext, "APP", null, isWarning);
    }

}

export class GraphGroupItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/GraphGroup", coreState, coreContext, "APP", null, isWarning);
    }

}

export class GraphUserItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/GraphUser", coreState, coreContext, "APP", null, isWarning);
    }

}

