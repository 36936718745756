import React, { createContext, useState, useEffect, useContext } from 'react';
import { useCoreStateContext } from 'spark-core-dx/contexts'
export const HomeDomainContext = createContext();

export function HomeDomainContextProvider({ children }) {

    const coreState = useCoreStateContext();

    useEffect(() => {
        return () => {
            coreState.clear()
        }
    }, [])

    return (
        <HomeDomainContext.Provider value={'Test'}>
            {children}
        </HomeDomainContext.Provider>

    );
}
export const useHomeDomainContext = () => useContext(HomeDomainContext);