/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import {
AGAppConfigItemService         
,AuthUserItemService         
,ClientKeySecretItemService         
,RefreshTokenItemService         
,TenantAppItemService         
,TenantItemService         
,TokenSecretItemService         
,GraphApplicationItemService         
,GraphGroupItemService         
,GraphUserItemService} from './ItemServices';
import {ItemServiceFactory as BaseItemServiceFactory} from 'spark-core-dx/services'

export class ItemServiceFactory {

get ItemServiceEnum() {
  return {
      ...BaseItemServiceFactory.ItemServiceEnum,
    AGAppConfig:"AGAppConfig",
    AuthUser:"AuthUser",
    ClientKeySecret:"ClientKeySecret",
    RefreshToken:"RefreshToken",
    TenantApp:"TenantApp",
    Tenant:"Tenant",
    TokenSecret:"TokenSecret",
    GraphApplication:"GraphApplication",
    GraphGroup:"GraphGroup",
    GraphUser:"GraphUser",
  }
}

GetItemService(itemServiceName, coreState, coreContext, isWarning) {
    if(itemServiceName === undefined) throw new Error('Item service name not found, please regenerate the Item Services to resolve this issue.');
    let itemService;
    switch(itemServiceName.toLowerCase()){
        case this.ItemServiceEnum.AGAppConfig.toLowerCase():
             itemService = new AGAppConfigItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.AuthUser.toLowerCase():
             itemService = new AuthUserItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.ClientKeySecret.toLowerCase():
             itemService = new ClientKeySecretItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.RefreshToken.toLowerCase():
             itemService = new RefreshTokenItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.TenantApp.toLowerCase():
             itemService = new TenantAppItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.Tenant.toLowerCase():
             itemService = new TenantItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.TokenSecret.toLowerCase():
             itemService = new TokenSecretItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.GraphApplication.toLowerCase():
             itemService = new GraphApplicationItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.GraphGroup.toLowerCase():
             itemService = new GraphGroupItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.GraphUser.toLowerCase():
             itemService = new GraphUserItemService(coreState, coreContext, isWarning);
             break;
        default:
            itemService = BaseItemServiceFactory.GetItemService(itemServiceName, coreState, coreContext);
            break;
        }
        return itemService;
    }

ValidateItemName(itemName) {
    let isValid = false;
    for (const key in this.ItemServiceEnum){
        if (typeof(this.ItemServiceEnum[key]) === "string" &&
                this.ItemServiceEnum[key].toLowerCase() == itemName.toLowerCase())
                {
                    isValid = true;
                    break;
                }
        }
     return BaseItemServiceFactory.ValidateItemName(itemName);
 }
}
