import React from 'react';
import { Outlet } from 'react-router-dom';
import { StandardLayout } from 'spark-core-dx/components';
import { HomeDomainContextProvider } from './HomeDomainProvider';

//Loads screens/items dynamically.
//No custom, always 1 to 1.

const HomeDomain = () => {
    return (
        <HomeDomainContextProvider>
            <StandardLayout>
                <Outlet />
            </StandardLayout>
        </HomeDomainContextProvider>
    );
};

export default HomeDomain;