

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'spark-core-dx/sparkExtensions';
import App from './App';
import configData from './config.json'
import { SparkReportWebVitals } from 'spark-core-dx';
import { BrowserRouter as Router } from 'react-router-dom';
import { CoreContextProvider } from 'spark-core-dx/contexts';
import { CoreStateContextProvider } from 'spark-core-dx/contexts';
import { AGALContextProvider } from 'spark-core-dx/contexts';
import { Theme } from 'spark-core-dx/components'
const rootElm = document.getElementById('root');
rootElm.classList.add("app");
const root = ReactDOM.createRoot(rootElm);
window.configData = configData

root.render(
  <Router>
    <CoreStateContextProvider>
      <AGALContextProvider>
        <CoreContextProvider>
          <Theme
            onCustomIndex={async () => {
              await import('spark-core-dx/sparkIndex.css')
            }}
            onCustomSparkApp={async () => {
              await import('spark-core-dx/sparkApp.css')
            }} >
            <App />
          </Theme>
        </CoreContextProvider>
      </AGALContextProvider>
    </CoreStateContextProvider >
  </Router >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
SparkReportWebVitals();

//Extentions


